<template>
  <button
    type="button"
    class="button-cta-ico"
    :class="{
      'is-ico-left': ['play'].includes(buttonType),
      'is-ico-right': ['expand', 'close'].includes(buttonType),
      'is-small': isSmall,
    }"
  >
    <!-- Play Ico -->
    <div class="button-cta-ico__icon" v-if="buttonType === 'play'">
      <div class="button-cta-ico__icon__wrap">
        <img
          src="@/assets/img/ico/ico-play-blue.svg"
          width="10"
          height="10"
          alt="Play"
          class="button-cta-ico__icon__wrap__img"
        />
      </div>
    </div>
    <!-- Text -->
    <div
      class="button-cta-ico__text text-xs-1 is-text-uppercase"
      :class="{
        'text-xs-1': !isSmall,
        'text-xxs-1': isSmall,
      }"
    >
      <span>{{ label }}</span>
    </div>
    <!-- Expand Ico -->
    <div class="button-cta-ico__icon" v-if="buttonType === 'expand'">
      <div class="button-cta-ico__icon__wrap">
        <img
          src="@/assets/img/ico/ico-plus-blue.svg"
          width="15"
          height="15"
          alt="Expand"
        />
      </div>
    </div>
    <!-- Close Ico -->
    <div class="button-cta-ico__icon" v-if="buttonType === 'close'">
      <div class="button-cta-ico__icon__wrap">
        <img
          src="@/assets/img/ico/ico-close-blue.svg"
          width="15"
          height="15"
          alt="Expand"
        />
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: 'WidgetButtonCtaIco',
}
</script>

<script setup>
const props = defineProps({
  label: String,
  buttonType: {
    type: String,
    default: 'play',
  },
  isSmall: {
    type: Boolean,
    default: false,
  },
})
</script>

<style lang="scss">
@import './style.scss';
</style>
